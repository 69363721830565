import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastrHelperService } from 'src/app/shared/services/toastr-helper/toastr-helper.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private _auth: AuthService,
    private _router: Router,
    // private _toastrHelper: ToastrHelperService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.status == 0) {
        this._auth.removeUserData();
        // this._toastrHelper.errorMessage('Error happened, try again later.');
        this._router.navigate(['/auth']);
      }
      
      throw err;
    }));
  }
}
