import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry.addSvgIcon(
      'driver-filter',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/filter.svg'),
    );
    iconRegistry.addSvgIcon(
      'driver-edit',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/edit.svg'),
    );
    iconRegistry.addSvgIcon(
      'driver-trash',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/trash.svg'),
    );
    iconRegistry.addSvgIcon(
      'routing',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/routing.svg'),
    );
    iconRegistry.addSvgIcon(
      'search',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/search-icon.svg'),
    );
  }
}
