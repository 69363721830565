import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { StorageNames } from '../../classes/storage-names';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _auth: AuthService,
    private _router: Router,
    // private _toastrHelper: ToastrHelperService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem(StorageNames.TOKEN);

    if (!token) {
      return next.handle(request);
    }

    let clonedRequest = request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token)
    });

    return next.handle(clonedRequest).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.status == 401) {
        this._auth.removeUserData();
        // this._toastrHelper.errorMessage('Login session expired');
        this._router.navigate(['/auth']);
      }

      throw err;
    }));
  }
}
