import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './shared/services/auth/auth.service';
import { LocalLanguageService } from './shared/services/languages/local-language/local-language.service';
import { IconRegistryService } from './shared/services/mat-icon-registry/icon-registry.service';
import { TranslateHelperService } from './shared/services/translate-helper/translate-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'helptk-shop-admin-dashboard';
  _ = inject(IconRegistryService);
  constructor(
    private _translateHelper: TranslateHelperService,
    private _router: Router,
    private _languages: LocalLanguageService,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this._translateHelper.initLang();
    this._languages.checkLanguages();
    this._auth.checkUserLocalData();
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        let body = document.querySelector('body');

        body?.style.removeProperty('overflow');
        body?.style.removeProperty('padding-right');
      }
    });
    document.getElementById('loader')?.remove();
  }
}
