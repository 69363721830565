import { environment } from 'src/environments/environment.development';

export class EndPoints {
  static readonly AUTH: string = environment.API + '/Authenticate';
  static readonly CATEGORY: string = environment.API + '/Category';
  static readonly BRANCHES: string = environment.API + '/Branches';
  static readonly ITEMS: string = environment.API + '/Items';
  static readonly PRODUCT_MANAGEMENT: string =
    environment.API + '/ProductManagement';
  static readonly SALE_INVOICE: string = environment.API + '/SaleInvoice';
  static readonly SALE_RETURN: string = environment.API + '/SaleReturn';
  static readonly PURCHASE_INVOICE: string =
    environment.API + '/PurchaseInvoice';
  static readonly REQUEST_PRICE: string = environment.API + '/RequestPrice';
  static readonly INVOICE_REPORTS: string = environment.API + '/InvoiceReports';
  static readonly REQUEST_PRICE_REPORTS: string =
    environment.API + '/RequestPriceReport';
  static readonly CLIENT: string = environment.API + '/Client';
  static readonly CLIENT_PAYMENT: string = environment.API + '/ClientPayment';
  static readonly SUPPLIER: string = environment.API + '/Supplier';
  static readonly SUPPLIER_PAYMENT: string =
    environment.API + '/SupplierPayment';
  static readonly EMPLOYEE: string = environment.API + '/Employee';
  static readonly EMPLOYEE_PAYMENT: string = environment.API + '/EmployeePay';
  static readonly SHARED: string = environment.API + '/Shared';
  static readonly PURCHASE_RETURN: string = environment.API + '/PruchaseReturn';
  static readonly STORES: string = environment.API + '/Stores';
  static readonly UNIT: string = environment.API + '/Unit';
  static readonly SaleReport: string = environment.API + '/SaleReport';
  static readonly ProductSaleReport: string =
    environment.API + '/ProductSaleReport';
  static readonly EXPENSE_INVOICE: string = environment.API + '/ExpenseInvoice';
  static readonly SaleReturnReport: string =
    environment.API + '/SaleReturnReport';
  static readonly PurchaseExpenseReport: string =
    environment.API + '/PurchaseExpenseReport';
  static readonly PurchaseReturnReport: string =
    environment.API + '/PurchaseReturnReport';
  static readonly BankReport: string = environment.API + '/BankReport';
  static readonly BondsReport: string = environment.API + '/BondsReport';
  static readonly FullReport: string = environment.API + '/FullReport';
  static readonly BONDS: string = environment.API + '/Bonds';
  static readonly DELIVERY: string = environment.API + '/Delivery';
  static readonly VAT: string = environment.API + '/Vat';
  static readonly VAT_DELIVERY: string = environment.API + '/VatDelievery';
  static readonly SUBSCRIPTION: string = environment.API + '/Subscription';
  static readonly ZATCA: string = environment.API + '/Zatca';
  static readonly EMP_PAY_TYPE: string = environment.API + '/EmpPayType';
  static readonly EXPENSE: string = environment.API + '/Expense';
  static readonly CONFIGURATION: string = environment.API + '/Configuration';
  static readonly TECHNICAL_SUPPORT: string =
    environment.API + '/TechnicalsSupport';
  static readonly ORDERS: string = environment.API + '/Orders';
  static readonly USERS_LIST: string = environment.API + '/UsersList';
  static readonly USER_PERMISSION: string = environment.API + '/UserPermission';
  static readonly USER_GROUP: string = environment.API + '/UserGroup';
  static readonly USERS_LOG: string = environment.API + '/UsersLogs';
  static readonly COMPANY_INFO: string = environment.API + '/CompanyInfo';
  static readonly PRICING_TYPES: string =
    environment.API_MASTER + '/PricingType';
  static readonly VAT_MASTER: string = environment.API_MASTER + '/Vat';
  static readonly EMAIL_VERIFICATION: string =
    environment.API + '/EmailVerification';
  static readonly PHONE_VERIFICATION: string =
    environment.API + '/PhoneVerification';
  static readonly PASSWORD: string = environment.API + '/Password';
  static readonly CUSTOMER_INFO: string = environment.API + '/CustomerInfo';
  static readonly EMPLOYEE_SHIFT: string = environment.API + '/EmployeeShift';
  static readonly EMPLOYEE_SHIFT_DETAILS: string =
    environment.API + '/EmployeeShiftDetails';
  static readonly CASHIER_BOX_PARTS: string =
    environment.API + '/CashierBoxParts';
  static readonly CASH_REGISTER: string = environment.API + '/CashRegister';
  static readonly CASH_MANAGEMENT: string = environment.API + '/CashManagement';
  static readonly STOCK_REGULATION: string =
    environment.API + '/StockRegulation';
  static readonly DISTRIBUTION_NOTIFICATION: string =
    environment.API + '/DistributionNotification';
  static readonly STOCK_COUNT: string = environment.API + '/StockCount';
  static readonly PropertyKeys: string = environment.API + '/PropertyKeys';
  static readonly PropertyValues: string = environment.API + '/PropertyValues';
  static readonly Debit: string = environment.API + '/Debit';
  static readonly InvoiceSettings: string = environment.API + '/InvoiceSettings';


  // DELIVERY SECTION -------------------------------------
  static readonly GET_ALL_DRIVERS_STATUS =
    environment.DELIVERY_API + '/DriverStatus/get/all';
  static readonly GET_ALL_ACTIVE_DRIVERS_STATUS =
    environment.DELIVERY_API + '/DriverStatus/get/Active';
  static readonly GET_ALL_NATIONALITIES =
    environment.DELIVERY_API + '/Nationality/get';
  static readonly ADD_NEW_DRIVER = environment.DELIVERY_API + '/Driver/Add';
  static readonly UPDATE_DRIVER = environment.DELIVERY_API + '/Driver/Update';
  static readonly DELETE_DRIVER = environment.DELIVERY_API + '/Driver/Delete';
  static readonly ASSIGN_ORDERS = environment.API + '/Orders';
  static readonly SELECT_DRIVER =
    environment.DELIVERY_API + '/Driver/GetDriverInfoForShop';
  static readonly CHECK_DATA = environment.DELIVERY_API + '/Driver/ChekData';
  static readonly GET_CANCELLED_ORDERS =
    environment.DELIVERY_API + '/Order/Cancellation-Requests';
  static readonly SOCKET = 'https://apidelivery.helptk.shop/DriverTracking';
  static readonly MULTISHOP_ORDER_DELIVERY =
    environment.API + '/Orders/Delivery';
  static readonly DECLINE_CANCELLATION =
    environment.DELIVERY_API + '/Order/Cancellation-Requests/decline';
  static readonly APPROVE_DECLINE_CANCELLATION_AND_REASSIGN =
    environment.DELIVERY_API +
    '/Order/Cancellation-Requests/Approve-And-ReAssign';
  static readonly APPROVE_CANCELLATION_AND_ARCHIVE =
    environment.DELIVERY_API +
    '/Order/Cancellation-Requests/Approve-And-Archive';
}
