import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let lang = localStorage.getItem('Translate_Lang');
    if (!lang) lang = 'ar';
    const newRequest = request.clone({
      setHeaders: {
        'Accept-Language': lang == 'ar' ? 'AR' : 'EN',
      },
    });
    return next.handle(newRequest);
  }
}
