import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { EndPoints } from 'src/app/core/classes/end-points';
import { StorageNames } from 'src/app/core/classes/storage-names';
import { BundleInfo } from 'src/app/core/models/bundle-info';
import { UserInterface } from 'src/app/core/models/user';
import { UserPermission } from 'src/app/core/models/user-permission';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData$ = new BehaviorSubject<UserInterface | null>(null);
  userPermission$ = new BehaviorSubject<UserPermission | null>(null);
  userBundle$ = new BehaviorSubject<any>(null);
  userPermission!: UserPermission;

  constructor(private _http: HttpClient, private _router: Router) {}

  login(data: any): Observable<UserInterface> {
    return this._http.post<UserInterface>(`${EndPoints.AUTH}/Login`, data);
  }

  register(data: any): Observable<UserInterface> {
    return this._http.post<UserInterface>(`${EndPoints.AUTH}/Register`, data);
  }

  saveTokens(user: UserInterface) {
    if (!user) return;
    localStorage.setItem(StorageNames.USER, JSON.stringify(user));
    localStorage.setItem(StorageNames.TOKEN, user.JWTToken);
    localStorage.setItem(StorageNames.TOKEN_EXPIRE, user.ExpiresOn.toString());
    this.userData$.next(user);
  }

  removeUserData() {
    localStorage.removeItem(StorageNames.USER);
    localStorage.removeItem(StorageNames.TOKEN);
    localStorage.removeItem(StorageNames.TOKEN_EXPIRE);
    this.userData$.next(null);
  }

  isLoggedIn(): boolean {
    let tokenExpireDateStorage = localStorage.getItem(
      StorageNames.TOKEN_EXPIRE
    );
    let tokenStorage = localStorage.getItem(StorageNames.TOKEN);
    let userStorage = localStorage.getItem(StorageNames.USER);

    if (!tokenExpireDateStorage || !tokenStorage || !userStorage) return false;

    if (!this.userData$.value) {
      this.userData$.next(JSON.parse(userStorage));
    }

    if (this.userData$.value) {
      let currentDate = new Date();
      let tokenExpireDate = new Date(tokenExpireDateStorage);

      if (currentDate <= tokenExpireDate) return true;
    }

    return true;
    // return this.refreshToken().pipe(
    //   map((e: any) => {
    //     if (e) {
    //       this.saveTokens(e.data);
    //       return true;
    //     }

    //     this.removeUserData();
    //     return false;
    //   }),
    //   catchError((err) => {
    //     throw err;
    //   })
    // );
  }

  setUserPermission(userPerm: UserPermission) {
    this.userPermission = userPerm;
    this.userPermission$.next(userPerm);
  }

  setUserBundle(bundleInfo: BundleInfo) {
    this.userBundle$.next(bundleInfo);
  }

  getData(): Observable<UserInterface> {
    return this._http.get<UserInterface>(
      `${EndPoints.AUTH}/GetVendorDataAfterResubscription`
    );
  }

  isUserAdmin() {
    if (this.userPermission) {
      return this.userPermission.GroupId === 1;
    }

    return false;
  }

  isSuperAdmin() {
    return this.userData$.value?.IsSuperAdmin;
  }

  checkUserLocalData() {
    window.addEventListener(
      'storage',
      () => {
        const currentBrowserWasLoggedIn = this.userData$.value != null;
        if (currentBrowserWasLoggedIn && !this.isLoggedIn()) {
          this.removeUserData();
          this._router.navigate(['/auth']);
          return;
        } else if (!this.isLoggedIn()) {
          try {
            const newSavedUser = JSON.parse(
              localStorage.getItem(StorageNames.USER)!
            ) as UserInterface;
            if (newSavedUser) {
              this._router.navigate(['']);
            }
          } catch (e) {
            this.removeUserData();
            this._router.navigate(['/auth']);
          }
        } else if (this.isLoggedIn()) {
          try {
            const newSavedUser = JSON.parse(
              localStorage.getItem(StorageNames.USER)!
            ) as UserInterface;
            if (newSavedUser.UserId !== this.userData$.value?.UserId) {
              location.reload();
            }
          } catch (e) {
            this.removeUserData();
            this._router.navigate(['/auth']);
          }
        }
      }
    );
  }
}
