export const LANGUAGES: LocalLanguage[] = [
    {
        mainName: 'العربية',
        name: 'ARABIC',
        value: 'ar',
        icon: '/assets/imgs/flags/saudia.svg'
    },
    {
        mainName: 'English',
        name: 'ENGLISH',
        value: 'en',
        icon: '/assets/imgs/flags/united-kingdom.svg'
    },
    {
        mainName: 'हिंदी',
        name: 'HINDI',
        value: 'hindi',
        icon: '/assets/imgs/flags/india.svg'
    },
    {
        mainName: 'اردو',
        name: 'URDU',
        value: 'urdu',
        icon: '/assets/imgs/flags/pakistan.svg'
    }
];

export interface LocalLanguage {
    name: string,
    value: string,
    icon: string,
    mainName: string
}