import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, delay, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateHelperService {
  private _Lang$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  readonly TRANSLATE_LANG: string = 'Translate_Lang';
  readonly LANG_ATTRIBUTE: string = 'lang';
  readonly DIR_ATTRIBUTE: string = 'dir';

  constructor(private _translate: TranslateService) {}

  initLang() {
    let currentLang = this.getLang();
    this._translate.setDefaultLang(currentLang);
    this._translate.onDefaultLangChange.subscribe((language) => {
      let lang = language.lang;
      localStorage.setItem(this.TRANSLATE_LANG, lang);
      document.documentElement.setAttribute(
        'lang',
        lang == 'ar' || lang == 'urdu' ? 'ar' : lang
      );
      document.documentElement.setAttribute(
        'dir',
        lang == 'ar' || lang == 'urdu' ? 'rtl' : 'ltr'
      );
    });
  }

  setLang(lang: string) {
    this._translate.setDefaultLang(lang);
    this._Lang$.next(lang);
  }

  getLang(): string {
    let currentLang = localStorage.getItem(this.TRANSLATE_LANG);
    if (!currentLang) return this._translate.getDefaultLang();

    return currentLang;
  }

  getLanguage$(): Observable<string | null> {
    return this._Lang$.asObservable();
  }

  getTranslateValue(value: string) {
    let lang = this.getLang();
    let translatedValue = this._translate.translations[lang][value];

    if (!translatedValue) return value;

    return translatedValue;
  }

  getTranslateValueWithLang(value: string, lang: string) {
    let translatedValue = this._translate.translations[lang][value];

    if (!translatedValue) return value;

    return translatedValue;
  }

  getTranslateValueWithLangPipe(value: string, lang: string) {
    return this._translate.currentLoader.getTranslation(lang).pipe(
      map((res) => {
        return res[value] ? res[value] : value;
      })
    );
  }
}
