import { Injectable } from '@angular/core';
import { LANGUAGES, LocalLanguage } from './languages';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalLanguageService {

  private readonly _onLocalLanguageChange = new BehaviorSubject<LocalLanguage[] | null>(null);
  readonly LOCAL_LANGUAGES = 'LOCAL_LANGUAGES';

  get onLocalLanguageChange() {
    return this._onLocalLanguageChange.asObservable();
  }
  
  checkLanguages() {
    const languages = localStorage.getItem(this.LOCAL_LANGUAGES);
    if (!languages) {
      this.resetLanguages();
    }

    return this.getLanguages()!;
  }

  resetLanguages() {
    let languages = [LANGUAGES[0], LANGUAGES[1]];
    this.setLanguages(languages);
    return this.getLanguages()!;
  }

  setLanguages(languages: LocalLanguage[]) {
    localStorage.setItem(this.LOCAL_LANGUAGES, JSON.stringify(languages));
    this._onLocalLanguageChange.next(languages);
    return this.getLanguages()!;
  }

  setLanguageByIndex(language: LocalLanguage, index: number) {
    let languages = this.getLanguages();

    if (!languages) {
      languages = this.resetLanguages();
    }

    languages[index] = language;
    return this.setLanguages(languages);
  }

  getLanguages() {
    const languages = localStorage.getItem(this.LOCAL_LANGUAGES);
    if (!languages) return null;
    return JSON.parse(languages) as LocalLanguage[];
  }
}
